import { APP_BASE_HREF } from '@angular/common';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, inject } from '@angular/core';
import { HammerModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { provideAuth } from '@ideals/core/auth';
import { provideClarity } from '@ideals/core/clarity';
import { provideFeatureFlags } from '@ideals/core/feature-flags';
import { provideIntercom } from '@ideals/core/intercom';
import { provideLocalization } from '@ideals/core/localization';
import { providePlatform } from '@ideals/core/platform';
import { provideRootStore } from '@ideals/core/root-store';
import { providePageTitleStrategy, provideRouteReuseStrategy, provideUrlSerializer } from '@ideals/core/strategies';
import { provideHelpCenterStore } from '@ideals/help-center/help-center';
import { BreadcrumbsService } from '@ideals/services/breadcrumbs';
import { provideTheming } from '@ideals/theming';

import { baseHrefInterceptor } from './data/interceptors/base-href';
import { errorInterceptor } from './data/interceptors/error';
import { exhaustInterceptor } from './data/interceptors/exhaust';
import { provideErrorHandler } from './data/strategies/error-handler';
import { environment } from './environments/environment';
import { buildSpecificProviders } from './providers';

import { rootRoutes } from './root.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(HammerModule),
    provideHttpClient(
      withInterceptors([
        baseHrefInterceptor,
        exhaustInterceptor,
        errorInterceptor,
      ]),
      withInterceptorsFromDi(),
    ),
    provideAnimations(),
    provideAuth({ authProviderUrl: environment.authProviderUrl }),
    provideClarity(environment.clarityEnabled, environment.clarityKey),
    provideErrorHandler(),
    provideFeatureFlags({
      key: environment.featureFlagsKey,
      name: environment.featureFlagsName,
      url: environment.featureFlagsProviderUrl,
    }),
    provideIntercom(environment.intercomEnabled, environment.coviewKey),
    provideLocalization(),
    providePageTitleStrategy(),
    providePlatform(),
    provideRootStore(),
    provideRouter(rootRoutes),
    provideRouteReuseStrategy(),
    provideTheming(),
    provideUrlSerializer(),
    provideHelpCenterStore(environment.helpCenterUrl),
    {
      provide: APP_BASE_HREF,
      useFactory: () => window.location.origin,
    },
    {
      multi: true,
      provide: APP_INITIALIZER,
      useFactory: () => {
        const breadcrumbsService = inject(BreadcrumbsService);

        return () => {
          breadcrumbsService.initialize();
        };
      },
    },
    ...buildSpecificProviders,
  ],
};
