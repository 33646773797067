import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

import { HttpError, HttpErrorBadRequest, HttpMethod, HttpStatus, RequestError, RequestErrorDetails } from '../../models';

type ErrorCode =
  | 'AccessDeniedErrorCode'
  | 'CategoryUnavailable'
  | 'CmekNotActivated'
  | 'DocumentNameExist'
  | 'FileDownloadException'
  | 'FileSizeIsTooBig'
  | 'FileWithoutIndexes'
  | 'IncorrectFileContent'
  | 'IncorrectFileExtension'
  | 'IncorrectFileName'
  | 'QuestionAnswered'
  | 'QuestionPostUnavailable'
  | 'QuestionUnavailable'
  | 'StorageLimit';

const ERROR_TRANSLATIONS: Record<ErrorCode, string> = {
  AccessDeniedErrorCode: 'common.ERROR.insufficient_permissions',
  CategoryUnavailable: 'common.ERROR.insufficient_permissions',
  CmekNotActivated: 'documents.Upload.Error.CMEK',
  DocumentNameExist: 'common.ERROR.name_is_used',
  FileDownloadException: 'documents.Toaster.TEXT.File_couldn\'t_be_downloaded',
  FileSizeIsTooBig: 'common.Error.Failed_image',
  FileWithoutIndexes: 'documents.ImportStructure.Error.No_index_found_in_file',
  IncorrectFileContent: 'common.TEXT.Import_failed',
  IncorrectFileExtension: 'common.Error.Unsupported_file',
  IncorrectFileName: 'Incorrect file name',
  QuestionAnswered: 'common.ERROR.insufficient_permissions',
  QuestionPostUnavailable: 'common.ERROR.insufficient_permissions',
  QuestionUnavailable: 'QA.Error.Question_unavailable',
  StorageLimit: 'documents.Upload.Error.Storage_limit',
};
const HTTP_ERROR_STATUSES: Record<number, HttpStatus> = {
  0: 'No Response',
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  409: 'Conflict',
  503: 'Service Unavailable',
};

enum HttpStatusNoConnectionCode {
  NoConnection = 0,
}

export function normalizeHttpError(method: string, response: HttpErrorResponse): HttpError | RequestError {
  if ((response.error as { errorCode: string; })?.errorCode === undefined) {
    return normalizeHttpErrorOld(method as HttpError['method'], response);
  }

  const error = response.error as {
    readonly detail: string;
    readonly errorCode: number;
    readonly errors?: RequestErrorDetails;
  };

  return {
    detail: ERROR_TRANSLATIONS[error.detail as ErrorCode] ?? error.detail,
    errorCode: error.errorCode,
    errors: error.errors,
    method: method as HttpMethod,
    status: HTTP_ERROR_STATUSES[response.status] ?? 'Internal Server Error',
  } satisfies RequestError;
}

function normalizeHttpErrorOld(method: HttpMethod, response: HttpErrorResponse): HttpError {
  switch (response.status as HttpStatusNoConnectionCode | HttpStatusCode) {
    case HttpStatusNoConnectionCode.NoConnection: {
      return { method, status: 'No Response' };
    }
    case HttpStatusCode.BadRequest: {
      return normalizeHttpErrorOldBadRequest(method, response);
    }
    case HttpStatusCode.Unauthorized: {
      return { method, status: 'Unauthorized' };
    }
    case HttpStatusCode.Forbidden: {
      return {
        error: response.error,
        message: ERROR_TRANSLATIONS[(response.error as { message: ErrorCode; })?.message] ?? undefined,
        method,
        status: 'Forbidden',
      };
    }
    case HttpStatusCode.NotFound: {
      return { method, status: 'Not Found' };
    }
    case HttpStatusCode.Conflict: {
      return {
        error: response.error,
        method,
        status: 'Conflict',
      };
    }
    case HttpStatusCode.ServiceUnavailable: {
      return { method, status: 'Service Unavailable' };
    }
    default: {
      return { method, status: 'Internal Server Error' };
    }
  }
}

function normalizeHttpErrorOldBadRequest(method: HttpError['method'], response: HttpErrorResponse): HttpErrorBadRequest {
  const defaultGeneralMessage = 'common.TEXT.something_went_wrong';
  const logIncorrectFormat = (): void => {
    console.error(`Incorrect response format: ${method}/Bad Request/${JSON.stringify(response.error)}`);
  };

  if (response.error == null || typeof response.error !== 'object') {
    logIncorrectFormat();

    return {
      message: defaultGeneralMessage,
      method,
      status: 'Bad Request',
      type: 'General',
    };
  }

  const error = response.error as {
    errors?: Record<string, ErrorCode>;
    message?: ErrorCode;
    type?: string;
  };
  const type: string = error.type ?? 'GENERAL';

  if (type === 'GENERAL') {
    if (!error.message) {
      logIncorrectFormat();
    }

    return {
      error,
      message: ERROR_TRANSLATIONS[error.message!] ?? error.message ?? defaultGeneralMessage,
      method,
      status: 'Bad Request',
      type: 'General',
    };
  }

  if (type === 'DETAILED') {
    if (typeof error.errors !== 'object' || Object.keys(error.errors).length === 0) {
      logIncorrectFormat();
    }

    const errors: Record<string, string> = {};

    for (const key in error.errors) {
      errors[key] = ERROR_TRANSLATIONS[error.errors[key]] ?? error.errors[key];
    }

    return {
      method,
      errors,
      status: 'Bad Request',
      type: 'Detailed',
    };
  }

  logIncorrectFormat();

  return {
    message: defaultGeneralMessage,
    method,
    status: 'Bad Request',
    type: 'General',
  };
}
