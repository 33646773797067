import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { uniqueId } from '@ideals/utils/unique-id';

import { ShortcutsManager } from '../shortcuts-manager/shortcuts-manager';

import { ToastRef } from './toast-ref';

import { ToastLikeComponent, ToastMessage, ToastMessageButton, ToastMessageData } from './toast.model';

interface ToastMessageConfig {
  readonly buttons?: ToastMessageButton[];
  readonly closeable?: boolean;
  readonly icon?: string;
  readonly id?: string; // to avoid toasts duplication
  readonly life?: number;
  readonly progress$?: Observable<number>;
  readonly sticky?: boolean;
}

const LIFE_WITHOUT_BUTTONS = 5000;
const LIFE_WITH_BUTTONS = 10000;

@Injectable({ providedIn: 'root' })
export class ToastService {
  #toastComponent?: ToastLikeComponent;

  error(message: string, config?: ToastMessageConfig): ToastRef {
    return this.#add({
      detail: message,
      icon: 'icon-exclamation-octagon',
      severity: 'error',
    }, config);
  }

  info(message: string, config?: ToastMessageConfig): ToastRef {
    return this.#add({
      detail: message,
      icon: 'icon-info-circle',
      severity: 'info',
    }, config);
  }

  progress(message: string, config?: ToastMessageConfig): ToastRef {
    return this.#add({
      detail: message,
      icon: 'progress-circle',
      severity: 'info',
    }, config);
  }

  success(message: string, config?: ToastMessageConfig): ToastRef {
    return this.#add({
      detail: message,
      icon: 'icon-check-circle',
      severity: 'success',
    }, config);
  }

  warn(message: string, config?: ToastMessageConfig): ToastRef {
    return this.#add({
      detail: message,
      icon: 'icon-exclamation-triangle',
      severity: 'warn',
    }, config);
  }

  protected setToastComponent(component?: ToastLikeComponent): void {
    this.#toastComponent = component;
  }

  #add(toastMessage: ToastMessage, config?: ToastMessageConfig): ToastRef {
    const buttonsLength = config?.buttons?.length ?? 0;
    const id = config?.id ?? (buttonsLength > 0 ? uniqueId() : toastMessage.detail);
    const message: ToastMessage = {
      ...toastMessage,
      closable: config?.closeable ?? true,
      data: {
        buttons: config?.buttons,
        progress$: config?.progress$,
      } as ToastMessageData,
      icon: config?.icon ?? toastMessage.icon,
      id,
      life: config?.life ?? (config?.buttons ? LIFE_WITH_BUTTONS : LIFE_WITHOUT_BUTTONS),
      sticky: config?.sticky,
    };
    const messages = this.#toastComponent!.toast().messages ?? [];
    const oldMessage = messages.find((msg) => msg.id === id);
    const shortcutsManager = new ShortcutsManager().scope(`toast-${Date.now()}`);

    if (oldMessage) {
      this.#toastComponent!.close(oldMessage);
    }

    this.#toastComponent!.messageService.add(message);

    const toastRef = new ToastRef(this.#toastComponent!, message);

    if (config?.buttons) {
      config.buttons.forEach((button) => {
        if (button.shortcut) {
          shortcutsManager.add(button.shortcut, () => {
            button.command();
            toastRef.close();
          });
        }
      });
    }

    shortcutsManager.merge();
    toastRef.closed$.subscribe(() => {
      shortcutsManager.destroy();
    });

    return toastRef;
  }
}
