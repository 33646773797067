import { inject, Injectable } from '@angular/core';

import { IntercomService } from '@ideals/core/intercom';
import { LocalStorageService } from '@ideals/services/local-storage';

import { ProjectGroupRole, ProjectUser } from '../../models';

@Injectable({ providedIn: 'root' })
export class OperationTrackerService {
  readonly #intercomService = inject(IntercomService);
  readonly #localStorageService = inject(LocalStorageService);

  #config: Record<string, string> = {};
  #counters: Record<string, number> = {};

  calculate(key: string, currentUser: ProjectUser, roles?: ProjectGroupRole[]): void {
    if (!(key in this.#counters)) {
      return;
    }

    const role = currentUser.group.role;

    if (roles && !roles.includes(role)) {
      return;
    }

    this.#counters[key]++;
    this.#localStorageService.setItem<number>(key, this.#counters[key]);
    this.#intercomService.updateIntercom({ [this.#config[key]]: this.#counters[key] });
  }

  reset(key: string): void {
    if (!(key in this.#counters)) {
      return;
    }

    this.#counters[key] = 0;
    this.#localStorageService.removeItem(key);
  }

  setup(config: Record<string, string>): void {
    this.#config = config;

    Object.keys(this.#config).forEach((key) => {
      this.#counters[key] = this.#localStorageService.getItem<number>(key) ?? 0;
    });
  }
}
