import { inject } from '@angular/core';
import { signalStore, withMethods } from '@ngrx/signals';
import { TranslateService } from '@ngx-translate/core';

import { ToastService } from '@ideals/services/toast';
import { withStoreEvents } from '@ideals/utils/with-store-events';

import { HttpError, HttpErrorBadRequestGeneral, HttpErrorForbidden, RequestError, RequestErrorDetails } from '../../models';

export function extractErrorMessage(errors: RequestErrorDetails = {}): string {
  return Object
    .keys(errors)
    .map((key) => errors[key]?.at(0)?.details)
    .at(0) ?? 'Unknown error message';
}

export const ErrorsStore = signalStore(
  { providedIn: 'root' },
  withStoreEvents<{
    forbidden: HttpErrorForbidden;
  }>(),
  withMethods((store) => {
    const toastService = inject(ToastService);
    const translateService = inject(TranslateService);

    return {
      requestError: (err: HttpError | RequestError): void => {
        const defaultMessage = 'common.Error.Something_went_wrong';
        const error = err as HttpError & RequestError;
        let message = '';

        switch (error.status) {
          case 'No Response': {
            message = 'common.ERROR.no_internet';
            break;
          }
          case 'Bad Request': {
            if (error.errors) {
              return;
            }

            message = (error as HttpErrorBadRequestGeneral).message ?? error.detail;
            break;
          }
          case 'Unauthorized': {
            return;
          }
          case 'Forbidden': {
            if (error.error) {
              store.emit('forbidden', error);

              return;
            }

            message = 'common.ERROR.insufficient_permissions';
            break;
          }
          case 'Not Found': {
            message = error.method === 'GET' ? 'common.ERROR.page_not_found' : defaultMessage;
            break;
          }
          default: {
            message = defaultMessage;
          }
        }

        toastService.error(translateService.instant(message) as string);
      },
    };
  }),
);
