import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
  output,
  viewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Toast, ToastModule as PrimeNgToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

import { getShortcutValue } from '@ideals/services/shortcuts-manager/get-shortcut-value.util';
import { ToastMessage, ToastMessageButton, ToastService } from '@ideals/services/toast';
import { asType } from '@ideals/utils/as-type';

import { ProgressCircleComponent } from '../progress-circle';
import { TooltipEllipsisOnlyDirective } from '../tooltip-ellipsis-only';

const TOAST_Z_INDEX = 999999999999;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    NgClass,
    PrimeNgToastModule,
    ProgressCircleComponent,
    TooltipEllipsisOnlyDirective,
    TooltipModule,
    TranslateModule,
  ],
  providers: [MessageService],
  selector: 'ideals-toast',
  standalone: true,
  styleUrl: './toast.component.scss',
  templateUrl: './toast.component.html',
})
export class ToastComponent implements OnInit, OnDestroy {
  readonly closed = output<ToastMessage>();

  readonly toast = viewChild.required(Toast);

  readonly #toastService = inject(ToastService);

  protected readonly asMessage = asType<ToastMessage>();
  protected readonly getShortcutValue = getShortcutValue;
  // Static z-index is needed to show toast above overlay panels
  protected readonly toastZIndex = TOAST_Z_INDEX;
  protected readonly tooltipZIndex = String(TOAST_Z_INDEX + 1);

  readonly messageService = inject(MessageService);

  ngOnInit(): void {
    this.#toastService['setToastComponent'](this);
    this.toast().onClose.subscribe(({ message }) => this.closed.emit(message as ToastMessage));
  }

  ngOnDestroy(): void {
    this.#toastService['setToastComponent']();
  }

  close(message: ToastMessage): void {
    const messageIndex = (this.toast().messages ?? []).indexOf(message);

    if (messageIndex !== -1) {
      this.toast().onMessageClose({ index: messageIndex, message });
    }
  }

  protected onButtonClick(message: ToastMessage, button: ToastMessageButton): void {
    button.command();
    this.close(message);
  }
}
