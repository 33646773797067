import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { forkJoin, map, Observable } from 'rxjs';

import { AuthService } from '@ideals/core/auth';
import { Culture } from '@ideals/core/localization';
import { AuthType } from '@ideals/models';
import { getDomain } from '@ideals/utils/get-domain';

import { AuthUser, CorporateAccountStatusId, UpdateAuthUser } from '../../models';
import { CorporateAccountService } from '../corporate-account';
import { TimeZoneService } from '../time-zone';

const AUTH_TYPE_MAP: Record<number, AuthType> = {
  0: 'Password',
  1: 'Password2FA',
  2: 'OpenId',
  3: 'WsFederation',
  4: 'Saml',
  5: 'Combined',
};

@Injectable({ providedIn: 'root' })
export class AuthUserService {
  readonly #authService = inject(AuthService);
  readonly #cookieService = inject(CookieService);
  readonly #corporateAccountService = inject(CorporateAccountService);
  readonly #httpClient = inject(HttpClient);
  readonly #timeZoneService = inject(TimeZoneService);
  readonly #translateService = inject(TranslateService);

  loadAuthUser(): Observable<AuthUser> {
    return forkJoin([
      this.#httpClient.get<{
        readonly authType: number;
        readonly canIdpUserSetPassword: boolean;
        readonly company: string;
        readonly contactPhone: string;
        readonly culture: Culture;
        readonly email: string;
        readonly firstName: string;
        readonly id: string;
        readonly isTimeZoneAutoUpdated: boolean;
        readonly lastName: string;
        readonly timeZone: string;
        readonly title: string;
      }>('/api/profile'),
      this.#corporateAccountService.loadAdministratedAccounts({
        onlyFullAdmins: true,
        statuses: [CorporateAccountStatusId.Active, CorporateAccountStatusId.Preparation],
      }),
      this.#corporateAccountService.loadAdministratedAccounts({
        statuses: [CorporateAccountStatusId.Active, CorporateAccountStatusId.Preparation],
      }),
    ]).pipe(
      map(([userDto, administratedAccounts, accounts]): AuthUser => {
        this.#timeZoneService.updateTimezoneIfNeeded(userDto.timeZone, userDto.isTimeZoneAutoUpdated);

        if (this.#authService.user.isInternalLogin) {
          this.#translateService.use(Culture.US);
        } else if ((this.#cookieService.get('culture') as Culture) !== userDto.culture) {
          this.#cookieService.set('culture', userDto.culture, { path: '/', domain: getDomain() });
          this.#translateService.use(userDto.culture);
        }

        return {
          accounts: accounts.administrated,
          administratedAccounts: administratedAccounts.administrated,
          authType: AUTH_TYPE_MAP[userDto.authType],
          canIdpUserSetPassword: userDto.canIdpUserSetPassword,
          company: userDto.company,
          contactPhone: userDto.contactPhone,
          culture: userDto.culture,
          email: userDto.email,
          firstName: userDto.firstName,
          forbidCreateTrialAccount: this.#authService.user.isInternalLogin ? false : administratedAccounts.forbidCreateTrialAccount,
          id: userDto.id,
          isTimeZoneAutoUpdated: userDto.isTimeZoneAutoUpdated,
          jobTitle: userDto.title,
          lastName: userDto.lastName,
          participatedAccounts: accounts.participated,
          timeZone: userDto.timeZone,
        };
      }),
    );
  }

  updateAuthUser(user: UpdateAuthUser): Observable<UpdateAuthUser> {
    const { jobTitle, ...updatedUser } = user;

    return this.#httpClient.put<{
      readonly company: string;
      readonly contactPhone: string;
      readonly culture: Culture;
      readonly firstName: string;
      readonly isTimeZoneAutoUpdated: boolean;
      readonly lastName: string;
      readonly timeZone: string;
      readonly title: string;
    }>('/api/profile', { ...updatedUser, title: jobTitle }).pipe(
      map((updateUserDto) => ({
        company: updateUserDto.company,
        contactPhone: updateUserDto.contactPhone,
        culture: updateUserDto.culture,
        firstName: updateUserDto.firstName,
        isTimeZoneAutoUpdated: updateUserDto.isTimeZoneAutoUpdated,
        jobTitle: updateUserDto.title,
        lastName: updateUserDto.lastName,
        timeZone: updateUserDto.timeZone,
      }))
    );
  }
}
